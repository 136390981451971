<template>
  <section class="section">
    <h3 class="section-title" v-if="memberId != undefined">
      {{ name }}님의 1:1 문의 내역<span class="cnt">({{total}}개)</span>
    </h3>
    <div v-if="hospitalId != undefined">
      <br>
      전체 | 시스템관련문의 | 신청자문의 | 정산문의 | 지원사업문의 | 기타<br>
    </div>
    <ul class="ask-view">
      <li class="ask-view__list" v-for="(qna ,index ) in qnaData" v-bind:key="index">
        <div class="ask-view__head">
          <p class="ask-view__sbj" style="word-wrap:break-word" v-html="handleNewLine(qna.question)"></p>
          <div class="ask-view-tail" style="" >
            <span class="date">2021-05-10 14:23</span>
            <button class="btn-reply">답글달기</button>
          </div>
        </div>
        <div class="ask-view__con" v-if="qna.answeredAt != null && qna.answerMode != 'edit'">
          <p  class="txt" style="word-wrap:break-word" v-html="handleNewLine(qna.answer)"></p>
          <div class="con-tail">
            <span class="date">{{ qna.answeredAt != null ? qna.answeredAt.substring(0, 16).replace("T", " ") : ""}}</span>
            <span class="writer">작성자 : {{qna.adminName}}</span>
            <button class="btn-mod" @click="changeAnswerMode(qna,index)">수정하기</button>
            <button class="btn-del" @click="deleteAnswer(qna)">삭제하기</button>
          </div>
        </div>
        <div class="ask-view__con" v-if="qna.answeredAt == null || qna.answerMode == 'edit'">
          <div class="ask-view__reply">
                      <textarea
                              class="reply-text-area"
                              placeholder="답글을 입력해주세요."
                              v-model="qna.answer"
                      ></textarea>
            <button class="btn-outline reply" @click="updateAnswer(qna)">
              <span class="txt">쓰기</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="table-foot clearfix">
      <Pagination
              :location="$route.path"
              ref="pagination"
              :page="nowPage"
              :page-size="param.pageSize"
              page-param-name="qnaPage"
              page-size-param-name="qnaPageSize"
              :extra-param-values="'\'page\':' + parentNowPage +   ',\'memberId\':' + memberId +   ',\'pageSize\':' + $route.query.pageSize"
      ></Pagination>
    </div>
  </section>
</template>

<script>
  import Pagination from "../components/Pagination";
export default {
  name: "MemberQna",
  props: ["memberId", "name","parentNowPage","hospitalId"],
  components: { Pagination },
  data() {
    return {
      nowPage: 1,
      total: 0,
      param: {
        page: 0,
        pageSize: 5,
        start: null,
        end: null,
        keyword: null,
        qnaType: "",
        memberId: null
      },
      qnaData: []
    };
  },
  beforeMount() {
    if (this.$route.query.qnaPage != "" && this.$route.query.qnaPage != null) {
      this.nowPage = this.$route.query.qnaPage;
    } else {
      this.nowPage = 1;
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },
    openQna(qna, index) {
      if (qna.show != true) {
        qna.show = true;
      } else {
        qna.show = false;
      }
      const copied = { ...qna };

      this.$set(this.qnaData, index, copied);

      console.log(qna.show);
    },
    requestList(memberId) {
      this.param.page = this.nowPage - 1;

      if (memberId == undefined || memberId == null ) {
        if (this.memberId != undefined && this.memberId != "") {
          this.param.memberId = this.memberId;
        }
      } else {
         this.param.memberId = memberId;
      }

      if (this.hospitalId != undefined && this.hospitalId != "") {
        this.param.hospitalId = this.hospitalId;
      }




      console.log(this.param);
      console.log("param", this.param);
      return this.$axios.get("qnas", { params: this.param });
    },
    async list(memberId) {
      console.log("list", memberId, this.memberId,this.hospitalId);
      if ((memberId != undefined || this.memberId != undefined ) || this.hospitalId != undefined) {
        const getListPromise = await this.requestList(memberId);
        const [response] = await Promise.all([getListPromise]);

        if (response.data.success) {
          this.qnaData = response.data.data;
          // for(let i=0; i<this.qnaData.length; i++) {
          //   this.showMap[this.qnaData[i].qnaId] = false;
          // }
          this.total = response.data.total;
          this.$refs["pagination"].setTotal(this.total);
          console.log("#####", this.total);
        }
      }

    },
    changeAnswerMode(qna, index) {
      qna.answerMode = "edit";

      const copied = { ...qna };

      this.$set(this.qnaData, index, copied);
    },
    deleteAnswer(qna) {
      if (confirm("답변 항목을 삭제하시겠습니까?")) {
        this.$axios
          .delete("qna/answer", { params: { qnaId: qna.qnaId } })
          .then(response => {
            if (response.data.success) {
              this.$alert.show(response.data.message);
              this.list();
            } else {
              this.$alert.show(response.data.message);
            }
          })
          .catch(ex => {
            if (ex.response.data.message.constructor == Object) {
              this.$alert.show(ex.response.data.message);
            }
          });
      }
    },
    async updateAnswer(qna) {
      var param = {
        qnaId: qna.qnaId,
        answer: qna.answer
      };

      this.$axios
        .put("qna/answer", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            this.list();
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    }
  }
};
</script>

<style scoped></style>
