<template>
  <section class="section">
    <h3 class="section-title">{{name}}님의 다른 신청정보</h3>
    <table class="tbl-list-default other-app-info">
      <caption>
        회원의 다른 신청정보
      </caption>
      <colgroup>
        <col class="col01" />
        <col class="col02" />
        <col class="col03" />
        <col class="col04" />
        <col class="col05" />
        <col class="col06" />
        <col class="col07" />
      </colgroup>
      <thead>
      <tr>
        <th scope="col">신청일</th>
        <th scope="col">신청자(관계)</th>
        <th scope="col">보험유형</th>
        <th scope="col">가족수/건강보험료</th>
        <th scope="col">진료과목/지원항목</th>
        <th scope="col">신청병원</th>
        <th scope="col">진행상태</th>
      </tr>
      </thead>
      <tbody>
                  <tr v-for="application in data" @click="openApplication(application.applicationId)" style="cursor: pointer"
                      v-bind:key="application.applicationId">
                    <td>{{ application.createdAt.substring(0, 16).replace("T", " ") }}</td>
                    <td>{{ application.name }}</td>
                    <td>{{ application.healthInsuranceType }}</td>
                    <td>
                      {{ application.familyCount }} | {{ application.premium }} 원
                    </td>
                    <td>{{ application.treatmentSubject }}<br />{{ application.medicalCare }}</td>
                    <td>
                      <span v-if="application.hospital != undefined" >{{ application.hospital.hospitalName }}</span>
                    </td>
                    <td>{{application.status}}</td>
                  </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  name: "MemberApplication",
  props: [ "memberId","name"],
  data() {
    return {

      data: [],
      param: {
        page: 0,
        pageSize: 1000,
        start: null,
        end: null,
        keyword: null,
        memberId: null,
      },
    };
  },
  beforeMount() {
    if (this.memberId != undefined) {
      this.list(this.memberId);
    }

  },
  mounted() {},
  methods: {

    openApplication(applicationId) {
      this.$router.push({ path: '/application/' + applicationId});

      console.log(applicationId);
    },
    requestList(memberId) {
      this.param.page = 0;
      this.param.memberId = memberId;
      return  this.$axios.get("applications-member" ,{ params: this.param } );
    },
    async list(memberId) {
       const getListPromise = await this.requestList(memberId);
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.data = response.data.data;

      }
    },
  }
};
</script>

<style scoped></style>
