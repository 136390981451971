<template>
  <div class="sub-container flex-sb">
    <div class="content col">
      <section class="section">
        <h2 class="page-title">회원관리</h2>
        <div class="table-search">
          <div class="ts-sch-col">
            <input
                    type="text"
                    class="ts-sch-col__inp inp-txt-common"
                    placeholder="이름, 이메일, 휴대폰 번호로 검색"
                    v-model="param.keyword"
                    @keyup.enter="list()"
            />
            <button class="ts-sch-col__btn">
              <span class="is-blind">검색하기</span>
            </button>
          </div>

          <div class="ts-select select-wrap">
            <select v-model="param.status" @change="list">
              <option value="">상태</option>
              <option value="before_certification">인증전</option>
              <option value="complete_verification">인증완료</option>
              <option value="withdrawal">탈퇴회원</option>
            </select>
          </div>
          <div class="ts-button">
            <button class="btn-search" @click="list()">검색하기</button>
          </div>
        </div>
        <div class="table-util">
          <div class="util-start">
            <button class="btn-del-select" @click="deleteSelected()">
              <span class="txt">선택항목삭제</span>
            </button>
            <span class="us-txt">검색결과: 전체 {{total}}건 중 {{data.length}}건</span>
          </div>
          <div class="util-end">
            <div class="tue-select select-wrap">
              <select v-model="param.pageSize" @change="list">
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="50">50개씩 보기</option>
              </select>
            </div>
          </div>
        </div>
        <table class="tbl-list-default application-mng">
          <colgroup>
            <col class="col01" />
            <col class="col02" />
            <col class="col02" />
            <col class="col03" />
            <col class="col03" />
            <col class="col16" />
            <col class="col02" />
          </colgroup>
          <thead>
          <tr>
            <th>
              <input
                      type="checkbox"
                      ref="check-all"
                      v-model="allCheckValue"
                      value="1"
                      id="chkAll"
                      @click="clickAllCheckbox()"
                      class="checkbox"
              />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>가입일</th>
            <th>이름</th>
            <th>이메일</th>
            <th>휴대폰번호</th>
            <th>신청내역</th>
            <th>상태</th>
          </tr>
          </thead>
          <tbody>
          <tr
                  v-for="member in data"
                  v-bind:key="member.memberId"
                  style="cursor: pointer"
          >
            <td>
              <input
                      type="checkbox"
                      :id="'checkbox_' + member.memberId"
                      v-model="deleteArray"
                      :value="member.memberId"
                      class="checkbox"
              />
              <label :for="'checkbox_' + member.memberId" class="is-blind"
              >선택</label
              >
            </td>
            <td @click="openDetail(member)" >
              {{ member.createdAt != null ? member.createdAt.substring(0, 16).replace("T", " ") : "" }}
            </td>
            <td @click="openDetail(member)">{{ member.name }}</td>
            <td @click="openDetail(member)">{{ member.email }}</td>
            <td @click="openDetail(member)">{{ member.mobile }}</td>
            <td @click="openDetail(member)">{{ member.applicationCount }}</td>
            <td @click="openDetail(member)">{{ statusMap[member.status] }}</td>
          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <Pagination location="member" ref="pagination" :page="nowPage"  :page-size="param.pageSize"></Pagination>
        </div>
      </section>
    </div>
    <div class="content col">

      <section class="section">
        <h3 class="section-title">회원 상세정보</h3>
        <member-detail ref="memberDetail" :member-id="memberId"></member-detail>

      <br>
      <member-application ref="member-application" :member-id="memberId" :name="memberData.name" ></member-application>
      <member-qna ref="member-qna" :member-id="memberId" :name="memberData.name" :parent-now-page="nowPage" ></member-qna>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
import MemberApplication from "../components/MemberApplication";
import MemberQna from "../components/MemberQna";
import Pagination from "../components/Pagination";
import MemberDetail from "../components/MemberDetail";
export default {
  name: "Member",
  components: {memberApplication: MemberApplication,MemberQna,Pagination,MemberDetail},
  data: () => ({
    review: null,
    nowPage: 1,
    pageSize: 10,
    total: 0,
    statusMap: {
      'before_certification': '인증전',
      'complete_verification': '인증완료',
      'withdrawal': '탈퇴',
    },
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null,
      status: "",
    },
    data: [],
    memberId: null,
    memberData: {},
    allCheckValue: [],
    deleteArray: [],
  }),
  mounted() {},
  beforeMount() {
    if (this.$route.query.page != '' && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }

    if (this.$route.query.pageSize != '' && this.$route.query.pageSize != null) {
      this.param.pageSize = this.$route.query.pageSize;
    } else {
      this.param.pageSize = 10;
    }

    this.list();
    if (this.$route.query.memberId != '' && this.$route.query.memberId != null) {
      this.memberId = this.$route.query.memberId;
    }
  },
  methods: {
    list() {
      console.log("call list");
      this.param.page = this.nowPage -1;
      this.$axios
        .get("members", {params: this.param})
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            this.data = response.data.data;
            this.total = response.data.total;
            this.$refs["pagination"].setTotal(this.total);
            if (this.memberId == undefined || this.memberId == null) {
              this.openDetail(this.data[0]);
            }
          }
        })
        .catch(ex => {
          console.log(ex);
          //this.$alert.show(ex.message);
        });
    },
    openDetail(memberData) {
      console.log("openDetail -- ");
      this.memberId = memberData.memberId;
      this.memberData = memberData;

      this.$refs["memberDetail"].getData(this.memberId);
      this.$refs["member-application"].list(this.memberId);
      this.$refs["member-qna"].list(this.memberId);
      console.log(this.memberData);
    },
    deleteSelected() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
                .delete("member", { params: { ids: ids } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                    this.deleteArray = [];
                    this.allCheckValue = [];
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for (var i = 0; i < this.data.length; i++) {
          this.deleteArray.push(this.data[i].memberId);
        }
      }
    },
  }
};
</script>
